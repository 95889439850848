import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FaPaperclip, FaMinus } from 'react-icons/fa';
import ReactImageCrop from 'react-image-crop';

import Content from 'src/components/ui/content/Content';
import { Button } from 'src/style/theme/Common';
import { Row } from 'src/style/PatentSearchStyle';
import CropImageModal from 'src/components/ui/interactive/modal/custom/CropImageModal';

import { LocAutocomplete } from 'src/components/search/loc/LocAutocomplete';
import { ILocBasic } from 'src/types/loc';

import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import {
  blue6,
  pink6,
  pink8,
  iceblue1,
  iceblue2,
  iceblue5,
  iceblue6,
  iceblue8,
  white,
  ultrawhite,
  blue3,
} from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import Tooltip from '../ui/interactive/Tooltip';
import { Api } from 'src/api/helpers/apiBase';
import { IPatentStatRange } from 'src/types/api/IndexApiTypes';
import { dateFormatter } from 'src/utils/Formatter';

const ButtonTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 4px;
  :hover {
    cursor: pointer;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  left: 101%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  margin: 0 32px 0 0;
  width: 80%;
  min-height: 30px;
  position: relative;
  display: flex;

  > span {
    padding: 0 0 0 17px;
    color: ${pink6};
  }
`;
const Input = styled.input.attrs<{ width: string }>({ type: 'text' })`
  width: ${props => props.width};
  padding: 5px 16px;
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  :placeholder {
    color: ${iceblue6};
  }
  :disabled {
    cursor: not-allowed;
  }
`;
const Column = styled.div<{ width: string }>`
  margin-right: 32px;
  width: ${props => props.width};
  color: ${iceblue1};
`;
const DropZoneStyle = styled.div`
  width: 100%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border-radius: 4px;
  border: dashed 2px ${iceblue6};
  background-color: ${white};
  color: ${iceblue6};

  span {
    margin-right: 16px;
  }
`;

const LocItem = styled.div`
  margin: 0 4px 0 0;
  padding: 4px 4px;
  border-radius: 4px;
`;
const Classes = styled(LocItem)`
  flex-shrink: 0;
  background-color: ${pink8};
  text-align: center;
`;
const TwLoc = styled(LocItem)`
  background-color: ${blue6};
`;
const EnLoc = styled(LocItem)`
  margin: 0;
  background-color: ${iceblue8};
`;

const DropzoneMsg = styled.span<{ color?: string }>`
  color: ${props => props.color};
`;

const StyledFaPaperclip = styled(FaPaperclip)`
  transform: rotateX(180deg);
`;

export const getLocClasses = (loc: ILocBasic) => {
  if (loc) {
    const locClass = loc.locClasses.substring(0, 2);
    const locSubclass = loc.locClasses.substring(2, 4);
    return `${locClass}-${locSubclass}`;
  }
  return '';
};
export const getLocDescription = (loc: ILocBasic | undefined) => {
  return loc ? (
    <>
      <Classes>{getLocClasses(loc)}</Classes>
      <TwLoc>{loc.twLoc}</TwLoc>
      {loc.enLoc && <EnLoc>{loc.enLoc}</EnLoc>}
    </>
  ) : (
    <></>
  );
};

interface IReduxMappingProps extends RouteComponentProps {
  isCropImageModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
}

const SELECTED_LOC_MAXIMUM = 3;

/** 以圖找圖 */
const SearchByImage: React.FC<IReduxMappingProps> = props => {
  const [locInput, setLocInput] = useState('');
  const [showLoc, setShowLoc] = useState(false);
  const [locList, setLocList] = useState<Array<ILocBasic>>([]);
  const [dateRange, setDateRange] = useState<IPatentStatRange>();

  useEffect(() => {
    const fetchDateRange = async () => {
      await Api()
        .get('patent/similar/image/dateRange')
        .then(res => setDateRange(res.data));
    };
    fetchDateRange();
  }, []);

  const handleSuggestionClick = (loc: ILocBasic) => {
    // Array.includes 無法僅針對 ILocBasic.locClasses 比較
    if (locList.find(selected => selected.locClasses === loc.locClasses) === undefined) {
      setLocList(prev => [...prev, loc]);
      setLocInput('');
    } else {
      alertMessage(SystemMessage.LOC_SELECTED);
    }
  };
  const handleSuggestionReset = (option: { shouldClearInput: boolean }) => {
    option.shouldClearInput && setLocInput('');
    setLocList([]);
  };

  const locMeetMaximum = locList.length >= SELECTED_LOC_MAXIMUM;
  const dateRangeString =
    dateRange && dateRange.startDate && dateRange.endDate
      ? dateFormatter(dateRange.startDate) + ' ~ ' + dateFormatter(dateRange.endDate)
      : '';

  return (
    <Content
      sector="專利檢索"
      feature="以圖找圖"
      subtitle={`資料範圍：${dateRangeString} 本國設計專利`}
    >
      <>
        <Row>
          <Label>
            <span>LOC =</span>
            <InputContainer>
              <Input
                width="100%"
                value={locInput}
                onChange={event => setLocInput(event.target.value)}
                placeholder={
                  locMeetMaximum
                    ? 'LOC 選取數量已達上限，若要選擇其他分類請先取消任意已選取的分類'
                    : '範例1: 麵包, 範例2: 01-01'
                }
                disabled={locMeetMaximum}
              />
              {!locMeetMaximum && (
                <LocAutocomplete
                  isShow={showLoc}
                  setShow={setShowLoc}
                  inputText={locInput}
                  handleSuggestionClick={handleSuggestionClick}
                />
              )}
            </InputContainer>
          </Label>
        </Row>
        {locList.length > 0 ? (
          locList.map((item, idx) => (
            <Row key={idx}>
              <Label>
                <span />
                <InputContainer>
                  {getLocDescription(item)}
                  <ButtonsContainer>
                    <ButtonTooltip
                      overlay="取消選取"
                      placement="top"
                      onClick={() =>
                        setLocList(prev => prev.filter((locObj, objIdx) => objIdx !== idx))
                      }
                    >
                      <FaMinus size={16} color={blue3} />
                    </ButtonTooltip>
                  </ButtonsContainer>
                </InputContainer>
              </Label>
            </Row>
          ))
        ) : (
          <Row>
            <Label>
              <span />
              <InputContainer>
                <span>尚未選取國際工業設計分類，將會自所有分類中搜尋</span>
              </InputContainer>
            </Label>
          </Row>
        )}
        <Row alignItems="flex-start">
          <Column width="10%">上傳圖片</Column>
          <Column width="80%">
            <DropzoneAndCrtl
              locClasses={locList}
              handleSuggestionReset={handleSuggestionReset}
              {...props}
            />
          </Column>
        </Row>
      </>
    </Content>
  );
};

interface IDropzoneAndCtrl extends IReduxMappingProps {
  locClasses: Array<ILocBasic>;
  handleSuggestionReset: (option: { shouldClearInput: boolean }) => void;
}

const DropzoneAndCrtl = ({
  locClasses,
  handleSuggestionReset,
  isCropImageModalOpen,
  openCustomModal,
  history,
}: IDropzoneAndCtrl) => {
  const [imgSrc, setImageSrc] = useState<string>();
  const [imgName, setImgName] = useState<string>();
  const [croppedImg, setCroppedImg] = useState<string>();
  const [crop, setCrop] = useState<ReactImageCrop.Crop>();
  const [croppedImgFile, setCroppedImgFile] = useState<File>();

  // DropzoneOptions
  const minSize = 0;
  const maxSize = 5242880;
  const acceptMime = 'image/png, image/jpeg, image/gif';
  const noClick = true;
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      const reader = new FileReader();
      const file = acceptedFiles[0];

      reader.onerror = () => {
        alertMessage(SystemMessage.SEARCH_BY_IMAGE_READER_ERROR);
      };
      reader.onload = () => {
        const binaryStr = reader.result as string;
        const strArr = binaryStr.split(';');
        setImageSrc(`data:${file.type};${strArr[1]}`);
        setImgName(file.name);
        openCustomModal(CustomModalType.CROP_IMAGE);
      };

      reader.readAsDataURL(new Blob([file]));
    },
    [openCustomModal],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    rejectedFiles,
    open,
  } = useDropzone({ accept: acceptMime, onDrop, minSize, maxSize, noClick });

  const isImageTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const handleSubmit = () => {
    if (croppedImgFile) {
      history.push('/result/searchByImage', {
        imgSrc: croppedImg,
        image: croppedImgFile,
        locClasses: locClasses,
        crop: crop,
      });
    } else {
      alertMessage(SystemMessage.SEARCH_CONDITION_INCOMPLETE);
    }
  };
  const handleReCrop = () => {
    openCustomModal(CustomModalType.CROP_IMAGE);
  };
  const handleReset = () => {
    setCroppedImg(prev => {
      prev && window.URL.revokeObjectURL(prev);
      return undefined;
    });
    setCrop(undefined);
    setImageSrc(undefined);
    setImgName(undefined);
    handleSuggestionReset({ shouldClearInput: true });
  };

  const imgRef = useRef<HTMLImageElement>(null);

  return (
    <>
      <Row>
        {croppedImg ? (
          <img ref={imgRef} alt="cropped" style={{ maxWidth: '100%', flex: 1 }} src={croppedImg} />
        ) : (
          <DropZoneStyle {...getRootProps()}>
            <input {...getInputProps()} />
            {!isDragActive && (
              <>
                {imgName ? (
                  <DropzoneMsg color={iceblue2}>{imgName}</DropzoneMsg>
                ) : (
                  <span>將檔案拖放到這裡，或</span>
                )}
                <Button template="primary" onClick={open}>
                  <StyledFaPaperclip />
                  選擇檔案
                </Button>
              </>
            )}
            {isDragAccept && <DropzoneMsg color="#32CD32">請將圖片放到這裡</DropzoneMsg>}
            {isDragReject && <DropzoneMsg color="#E57F7F">請選擇符合格式的圖片</DropzoneMsg>}
            {isImageTooLarge && <DropzoneMsg color="#E57F7F">上傳的圖片過大</DropzoneMsg>}
          </DropZoneStyle>
        )}
      </Row>
      <Row>建議圖片裁切後大小&ensp;242px&nbsp;*&nbsp;242px&ensp;以上</Row>
      {croppedImg ? (
        crop && (
          <Row>
            目前裁切範圍:&ensp;{Math.floor(crop.width || 0)}px&nbsp;*&nbsp;
            {Math.floor(crop.height || 0)}px
          </Row>
        )
      ) : (
        <Row>檔案大小限制：5mb&emsp;檔案種類限制：png,&ensp;jpeg&ensp;和&ensp;gif</Row>
      )}
      <Row justifyContent="flex-start">
        {croppedImg ? (
          <>
            <Button type="button" template="primary" onClick={handleSubmit}>
              查詢
            </Button>
            <Button type="button" template="primary-light" onClick={handleReCrop}>
              重新裁切
            </Button>
          </>
        ) : (
          <Button
            type="button"
            template="primary"
            disabled={!imgSrc}
            onClick={() => openCustomModal(CustomModalType.CROP_IMAGE)}
          >
            裁切圖片
          </Button>
        )}
        <Button type="button" onClick={handleReset}>
          清除
        </Button>
      </Row>
      {isCropImageModalOpen && (
        <CropImageModal
          imgSrc={imgSrc}
          setCroppedImg={setCroppedImg}
          crop={crop}
          setCrop={setCrop}
          setCroppedImgFile={setCroppedImgFile}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => ({
  isCropImageModalOpen: state.modalReducer.customModalType === CustomModalType.CROP_IMAGE,
});

const mapDispatchToProps = {
  openCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchByImage);
