import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import { numFormatter } from 'src/utils/Formatter';
import { getPatentKindName } from 'src/utils/PatentUtils';
import { blue2, blue3, blue7, blue8, iceblue1, ultrawhite } from 'src/style/theme/Color';

const PieChart = styled.div`
  margin: auto;
  width: 128px;
  height: 128px;
  position: relative;
  canvas {
    position: absolute;
  }
`;
const InnerCircle = styled.div<{ active?: boolean }>`
  margin: 14px;
  width: 100px;
  height: 100px;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  background-color: ${props => (props.active ? blue8 : ultrawhite)};
  cursor: pointer;
`;

const Title = styled.div`
  position: relative;
  top: 26px;
  width: 100%;
  text-align: center;
  color: ${iceblue1};
  margin: 2px 0px;
`;
const TotalCount = styled(Title)`
  color: ${blue2};
  font-weight: bold;
`;

interface IProps {
  statData: Array<number>;
  name: string;
  src: string;
  onClick: () => void;
  active: boolean;
}

export const PieChartArea: React.FC<IProps> = props => {
  let dataConfig = {
    datasets: [
      {
        data: props.statData,
        labels: [getPatentKindName('A'), getPatentKindName('B')],
        backgroundColor: [blue3, blue7],
        borderWidth: 0,
      },
    ],
  };

  // const totalCount = props.statData.A + props.statData.B + props.statData.U;

  return (
    <PieChart>
      <Pie
        data={dataConfig}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            enabled: false,
          },
          layout: {
            padding: {
              top: 0,
              right: 0,
              bottom: 10,
              left: 0,
            },
          },
        }}
      />
      <InnerCircle active={props.active} onClick={props.onClick}>
        <Title>{props.name}</Title>
        <TotalCount>{props.statData ? numFormatter(props.statData[0]) : 0}</TotalCount>
      </InnerCircle>
    </PieChart>
  );
};
