import styled, { css } from 'styled-components';
import {
  white,
  iceblue3,
  iceblue5,
  iceblue8,
  blue1,
  blue2,
  blue3,
  blue7,
  blue6rgba,
} from 'src/style/theme/Color';
import Tooltip from 'src/components/ui/interactive/Tooltip';

export const Container = styled.div`
  border-bottom: 1px solid #dee2e4;
  background-color: ${white};
`;

export const Message = styled.p`
  margin: 16px;
  color: #bdc9cb;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${iceblue5};
  padding: 10px 16px;
  :hover {
    cursor: pointer;
  }
`;

export const TaggedHeader = styled(Header)`
  background-color: ${blue6rgba(0.3)};
  :hover {
    cursor: auto;
  }
  svg {
    :hover {
      cursor: pointer;
    }
  }
`;

const scrollbarStyle = css`
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${blue1};
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${blue3};
  }
`;

export const Panel = styled.div<{ isShow: boolean }>`
  margin: 0 16px 8px 0;
  background-color: ${white};
  color: ${blue2};
  display: ${props => (props.isShow ? 'block' : 'none')};
  max-height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
  ${scrollbarStyle}
`;

export const Title = styled.div`
  max-width: 45%;
`;

export const TagWithTooltip = styled(Tooltip)`
  display: inline-block;
  color: ${blue2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40%;
`;

export const FacetLinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* 避免遮到scrollbar */
  margin-right: 2px;
  padding: 4px 8px 4px 16px;
  :hover {
    cursor: pointer;
    background-color: ${blue7};
  }
`;

export const FacetLinkName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 40px;
`;

export const FacetLinkCount = styled.span`
  font-size: 10px;
`;

export const FilteredKeyword = styled.span<{ isMatched: boolean }>`
  text-decoration: ${props => (props.isMatched ? 'underline' : '')};
`;

export const NoFilteredResult = styled.div`
  padding: 4px 16px;
  color: ${iceblue5};
`;

export const ClusterHeader = styled(Header)<{ color?: string }>`
  color: ${props => props.color || iceblue5};
  user-select: none;
  :hover {
    background-color: ${blue7};
    cursor: pointer;
  }
`;

export const ClusterTitle = styled.div`
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CollapseButton = styled.span<{ color?: string }>`
  display: flex;
  svg {
    width: 20px;
    height: 20px;
  }
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const ClusterPanel = styled.div<{ isShow: boolean }>`
  margin: 0;
  background-color: ${white};
  color: ${blue2};
  display: ${props => (props.isShow ? 'block' : 'none')};
  user-select: none;
`;

export const ClusterMapContainer = styled.div`
  width: 100%;
  height: 260px;
  background-color: #ffffff;
`;

export const ClusterAccordionsTitle = styled(Header)`
  color: #b3b9bc;
  user-select: none;
  :hover {
    cursor: auto;
  }
`;

export const ClusterBox = styled.div`
  margin-bottom: 8px;
  color: ${iceblue3};
`;

export const ClusterBoxHeader = styled(ClusterHeader)`
  color: ${iceblue3};
  padding: 6px 16px;
`;

export const ClusterBoxDetail = styled.div<{ isShow: boolean }>`
  display: ${props => (props.isShow ? 'block' : 'none')};
  border-left: 1px solid ${iceblue8};
  margin-left: 24px;
  p {
    padding: 0 16px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p:hover {
    cursor: pointer;
    background-color: ${blue7};
  }
`;
