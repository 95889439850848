import industrialClassReducer from 'src/redux/reducers/industrialClassReducer';
import patentSearchReducer from 'src/redux/reducers/patentSearchReducer';
import patentResultReducer from 'src/redux/reducers/patentResultReducer';
import patentFilterReducer from 'src/redux/reducers/patentFilterReducer';
import patentContentReducer from 'src/redux/reducers/patentContentReducer';
import modalReducer from 'src/redux/reducers/modalReducer';
import docdbSearchReducer from 'src/redux/reducers/docdbSearchReducer';
import docdbResultReducer from 'src/redux/reducers/docdbResultReducer';
import byImageSearchReducer from 'src/redux/byImage/search/reducer';
import byImageResultReducer from 'src/redux/byImage/result/reducer';
import memberReducer from 'src/redux/member/reducer';
import resultToolbarReducer from 'src/redux/result/toolbar/reducer';
import resultToolbarStatisticsReducer from 'src/redux/result/toolbar/statistics/reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  industrialClassReducer: industrialClassReducer,
  patentSearchReducer: patentSearchReducer,
  patentResultReducer: patentResultReducer,
  patentFilterReducer: patentFilterReducer,
  resultToolbarReducer: resultToolbarReducer,
  resultToolbarStatisticsReducer: resultToolbarStatisticsReducer,
  patentContentReducer: patentContentReducer,
  modalReducer: modalReducer,
  docdbSearchReducer: docdbSearchReducer,
  docdbResultReducer: docdbResultReducer,
  byImageSearchReducer: byImageSearchReducer,
  byImageResultReducer: byImageResultReducer,
  memberReducer: memberReducer,
});

export type ReduxAppState = ReturnType<typeof rootReducer>;

export default rootReducer;
