import { Auth } from 'src/apps/auth/Auth';

export enum Permission {
  ADD_MEMBER = 'km:member:add',
  EDIT_EXPERT = 'km:expert:edit',
}

export const check = (query: Permission): boolean => {
  const permissions: string[] = Auth().getPayload().permissions;
  switch (query) {
    case Permission.ADD_MEMBER:
      return permissions.find(it => it === Permission.ADD_MEMBER) ? true : false;
    case Permission.EDIT_EXPERT:
      return permissions.find(it => it === Permission.EDIT_EXPERT) ? true : false;
    default:
      return false;
  }
};

export const getAvailableFeatures = (features: Array<{ feature: string; path: string }>) => {
  const copyed = [...features];

  if (!check(Permission.EDIT_EXPERT)) {
    const idx = copyed.findIndex(i => {
      return i.path.endsWith('/expertDetailEdit');
    });
    copyed.splice(idx, 1);
  }

  if (!check(Permission.ADD_MEMBER)) {
    const idx = copyed.findIndex(i => {
      return i.path.endsWith('/manage');
    });
    copyed.splice(idx, 1);
  }

  return copyed;
};
