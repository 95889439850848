import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { white, whitergba, blue2, blue3 } from 'src/style/theme/Color';
import Filter from './Filter';
import Cluster from './Cluster';

const Container = styled.div<{ isShow: boolean }>`
  width: 248px;
  margin: -24px 0 0 32px;
  display: ${props => (props.isShow ? 'block' : 'none')};
`;

const TabList = styled.div`
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(99deg, ${blue3}, ${blue2});
  display: flex;
  align-items: flex-end;
  padding: 8px 16px 0 16px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 8px 16px;
  margin-right: 4px;
  line-height: 1;
  border-radius: 4px 4px 0 0;
  text-align: center;
  :hover {
    cursor: pointer;
  }

  transition: 0.3s ease;
  transition-property: color, background-color;

  ${props =>
    props.isActive
      ? css`
          color: ${blue2};
          background-color: ${white};
        `
      : css`
          color: ${white};
          background-color: ${whitergba(0.3)};
        `}
`;

const TabPanel = styled.div<{ isActive: boolean }>`
  overflow: auto;
  background-color: ${white};
  border-left: 1px solid #dee2e4;
  border-right: 1px solid #dee2e4;

  transition: opacity 0.5s ease-in-out;

  ${props =>
    props.isActive
      ? css`
          padding-top: 8px;
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `}
`;

interface IProps {
  isShow: boolean;
  activeTab: number;
  setActiveTab: (idx: number) => void;
}

/** 搜尋結果頁，左側Menu */
const LeftMenu: React.FC<IProps> = ({ isShow, activeTab, setActiveTab }) => {
  const tabs = [{ title: '篩選', panel: <Filter /> }, { title: '聚類', panel: <Cluster /> }];

  // 紀錄該 Tab 是否被 Click 過的 Array，click 過才 render Panel。
  // activeTab預設為true，其餘預設為false。
  // ps. 篩選也預設為true(因為要載入的DOM太大，不預設直接render的話會導致第一次click載入時間過久)
  const [mountedList, setMountedList] = useState<Array<boolean>>(
    [...new Array(tabs.length)].map((item, idx) => idx === 0 || idx === activeTab),
  );

  const handleTabClick = (idx: number) => {
    setActiveTab(idx);
    if (!mountedList[idx]) {
      setMountedList(mountedList.map((item, innerIdx) => innerIdx === idx || item));
    }
  };

  return (
    <Container isShow={isShow}>
      <TabList>
        {tabs.map((item, idx) => (
          <Tab key={item.title} isActive={activeTab === idx} onClick={() => handleTabClick(idx)}>
            {item.title}
          </Tab>
        ))}
      </TabList>
      {tabs.map((item, idx) => (
        <TabPanel key={item.title} isActive={activeTab === idx}>
          {mountedList[idx] && item.panel}
        </TabPanel>
      ))}
    </Container>
  );
};

export default LeftMenu;
