export interface IAlertModal {
  message: string;
  description?: string;
  callback?: () => void;
}

export interface IRedirectModal {
  path: string;
  message: string;
}

export enum LoginModalType {
  NONE,
  ALERT,
  FORM,
}

export enum CustomModalType {
  NONE,
  EDIT_COLLECTION,
  DELETE_COLLECTION,
  DELETE_CATEGORY,
  DELETE_RSS_FOLLOW,
  DELETE_RSS_FOLLOW_SHARE,
  DELETE_PATENT_SUBSCRIPTION,
  DELETE_PATENT_SUBSCRIPTION_SHARE,
  DISABLE_SUBMEMBER,
  PATENT_PRINT,
  CROP_IMAGE,
  CHART,
  INDUSTRIAL_CLASS_MANU,
  INDUSTRIAL_CLASS_SPEC,
  PATENT_DATA_SCOPE,
  MOBILE_MENU,
  INDUSTRIAL_CLASS_IPC_RANGE,
}
