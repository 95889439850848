import { AxiosError, AxiosResponse } from 'axios';
import { getGuestJwt } from 'src/apps/auth/LoginUtils';
import { ExcludedRedirectUrlRegExp } from 'src/routes/rules/PathRegExp';
import { alertMessage } from 'src/utils/ModalUtils';

export interface IErrorResponseData {
  resultCode: string;
  resultMessage: string;
}

export const errorHandler = (err: any) => {
  const error = err as AxiosError<IErrorResponseData>;
  if (error.response && error.response.data) {
    errorSwitchCase(error.response.data);
  } else {
    console.error(error);
  }
};

const changeJwt = async () => {
  try {
    const statusCode = await getGuestJwt();
    if (statusCode >= 200 && statusCode < 303) {
      window.location.reload();
    }
  } catch (error) {
    errorHandler(error);
  }
};

const errorSwitchCase = (errData: IErrorResponseData) => {
  const { resultCode: errCode, resultMessage: errMsg } = errData;
  switch (errCode) {
    // 嚴重警告
    case 'ip.invalid':
      window.location.href = '/invalid-ip';
      break;
    case 'mail.send.failed':
    case 'auth.deny.illegal.operation':
      alertMessage(errMsg);
      break;

    // 登入問題
    case 'login.failed':
    case 'login.tiponet.serial.number.missing':
    case 'captcha.expired':
    case 'captcha.mismatch':
    case 'email.notExisted': // 忘記密碼, 確認信重寄的信箱驗證
    case 'account.disabled':
    case 'account.confirm.necessary':
    case 'account.locked':
      alertMessage(errMsg);
      break;
    // e網通會員登入錯誤 - 該email已經在ipkm註冊
    case 'email.duplicate.error': {
      const redirectPath = '/user/forget-password';
      const redirectUrl = `${window.location.protocol}//${window.location.hostname}${window.location
        .port && ':' + window.location.port}${redirectPath}`;
      alertMessage(
        '您的EMAIL已申請過IPKM帳號，若您已忘記密碼，可使用忘記密碼功能。',
        '',
        () => (window.location.href = redirectUrl),
      );
      break;
    }
    // 確認信相關 - 應由頁面顯示
    case 'account.confirm.unnecessary':
    case 'account.confirm.expired':
      break;
    // 帳號 - 缺少行業別偏好
    case 'account.industrialClass.empty':
      alertMessage(errMsg);
      break;

    // TODO: Token 問題 - 尋找 Token 更新後的 Callback Msg 解法
    case 'auth.token.invalid':
    case 'auth.token.missing':
    case 'auth.token.expired':
    case 'ip.changed':
      changeJwt();
      break;

    // 不應顯示的錯誤 - 開發期 API 問題
    case 'url.notFound':
    case 'parameter.missing':
    case 'http.method.notAllowed':
      if (process.env.NODE_ENV === 'development') {
        alertMessage(errMsg);
        console.error(errCode);
        console.error(errMsg);
      }
      break;

    // 功能錯誤
    case 'parameter.invalid':
    case 'rss.follow.name.empty':
    case 'rss.follow.exceed.upper':
    case 'rss.follow.duplicated':
    case 'text.search.by.document.error':
    case 'text.PatentSubscription.size.max':
    case 'text.PatentSubscription.name.duplicated':
    case 'text.token.invalid': // bilingual
    case 'text.parameter.invalid': // bilingual
    case 'text.member.sub.add.limit':
    case 'text.pdfDownload.error':
    case 'text.search.by.image.error':
      alertMessage(errMsg);
      break;

    case 'error.field.format':
      alertMessage(errMsg);
      break;

    // 只印在 console
    case 'exception.unknown':
    default:
      if (process.env.NODE_ENV === 'development') {
        console.error(errCode);
      }
      console.error(errMsg);
  }
};

export const respInterceptorLogic = (response: AxiosResponse<any>) => {
  // for member whom modified profile email;
  if (response.headers.pageredirect) {
    const redirectPath = '/member/edit';
    const redirectUrl = `${window.location.protocol}//${window.location.hostname}${window.location
      .port && ':' + window.location.port}${redirectPath}`;

    const len = ExcludedRedirectUrlRegExp.length;
    let needRedirect = true;
    for (let i = 0; i < len; ++i) {
      if (window.location.pathname.match(ExcludedRedirectUrlRegExp[i])) {
        needRedirect = false;
      }
    }

    if (needRedirect) {
      const goto = (path: string) => {
        if (
          window.location.pathname !== path &&
          response.headers.pageredirect === 'account.confirm.necessary'
        ) {
          alertMessage(
            '請先完成會員資料修改流程, 並點選信件確認連結以完成啟用。',
            '',
            () => (window.location.href = path),
          );
        } else if (
          window.location.pathname !== path &&
          response.headers.pageredirect === 'account.industrialClass.empty'
        ) {
          alertMessage(
            '此帳號未選擇 行業別偏好，請至編輯會員資料頁面勾選以完成相關功能啟用。',
            '',
            () => (window.location.href = path),
          );
        }
      };

      goto(redirectUrl);
    }
  }

  return response;
};

export const errorInterceptorLogic = (error: any, source: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[axios Interceptor] ${source} Error:`);
    console.error(error);
  }

  return Promise.reject(error);
};
