import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { dateFormatter } from 'src/utils/Formatter';
import { toDropdownItem } from 'src/utils/IndustryUtils';
import { getStatRangeText } from 'src/components/trend/TrendsHelper';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';

import StatContent from 'src/components/ui/content/Showcase/IndustrialTrendsContent';
import StatSummary from './stat/StatSummary';
import StatCountry from './stat/StatCountry';
import StatIpc from './stat/StatIpc';
import StatApplicant from './stat/StatApplicant';
import { IIndustrialClassType, IIndustrialTrendApiParamType } from 'src/types/IndustryTypes';
import { blue2, ultrablackrgba, ultrawhite } from 'src/style/theme/Color';
import { Title } from 'src/style/IndustrialTrendStyle';
import IndustrialClassArrowDropdown from 'src/components/trend/industrialClass/ui/ArrowDropdown';
import ExpandableBox from 'src/components/expert/ExpandableBox';

const SearchBar = styled.div`
  height: 61px;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 2px 4px 8px 1px ${ultrablackrgba(0.1)};
`;
const SearchButton = styled.div`
  line-height: 61px;
  padding: 0 24px;
  text-align: center;
  flex-shrink: 0;
  border-radius: 0 4px 4px 0;
  background-color: ${blue2};
  color: ${ultrawhite};
  :before {
    content: '查詢';
  }
  :hover {
    cursor: pointer;
  }
`;
const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #e5eff1;
  padding: 32px 32px 0 32px;
  z-index: 0;
  background-color: #edf6f7;
`;
const Tab = styled.div<{ active?: boolean }>`
  position: relative;
  flex-wrap: no-wrap;
  padding-bottom: 13px;
  margin-right: 24px;
  font-size: 20px;
  color: ${props => (props.active ? '#424242' : '#828f92')};
  font-weight: ${props => props.active && 'bold'};
  :before {
    content: '';
    float: left;
    position: absolute;
    left: -5%;
    width: 110%;
    background: transparent;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 40px 0 0 ${props => (props.active ? blue2 : 'transparent')};
    height: 3px;
  }
  :hover {
    cursor: pointer;
  }
`;
const Content = styled.div`
  padding: 32px;
`;
const IpcStyle = styled.div`
  display: flex;
  > div {
    width: 275px;
  }
`;

const ExpandableBoxStyle = styled.div`
  > div {
    > div {
      margin-right: 65px;
    }
    > span {
      top: 0px;
      right: 0px;
    }
  }
`;

interface IProps {
  id: string;
}

interface IReduxMappingProps extends RouteComponentProps<IProps> {
  isLoading: boolean;
  industrialClassList: Array<IIndustrialClassType>;
}

// 統計分頁
const statTabs = [{ name: '發明公告', value: 'B' }, { name: '發明公開', value: 'A' }];
const IndustrialTrendsLayout: React.FC<IReduxMappingProps> = ({
  match,
  isLoading,
  industrialClassList,
}) => {
  const urlId = match.params.id;
  const getTitle = (item: IIndustrialClassType[]) => {
    return item.length === 0 ? '不分行業' : item[0] && item[0].description;
  };

  const [checkedItems, setCheckedItems] = useState<IIndustrialClassType[]>(
    urlId ? toDropdownItem([urlId]) : industrialClassList,
  );

  const [activeStatTab, setActiveStatTab] = useState(statTabs[0]);
  const [apiParams, setApiParams] = useState<IIndustrialTrendApiParamType>({
    industrialClassIds: urlId ? [parseInt(urlId)] : [],
    kind: activeStatTab.value,
  });
  const [title, setTitle] = useState<string>(getTitle([]));
  const [updateTime, setUpdateTime] = useState<string>();
  const [statTimeRange, setStatTimeRange] = useState<Array<string>>([]);
  // 行業別大類中類小類
  const [section, setSection] = useState<IIndustrialClassType | null>(null);
  const [division, setDivision] = useState<IIndustrialClassType | null>(null);
  const [group, setGroup] = useState<IIndustrialClassType | null>(null);
  // IPC 範圍列表
  const [ipcList, setIpcList] = useState<Array<string>>([]);

  useEffect(() => {
    // 更新中類 : 當大類更改時, 將中類目前選擇的內容清空
    setDivision(null);
  }, [section]);

  useEffect(() => {
    // 更新小類 : 當中類更改時, 將中類目前選擇的內容清空
    setGroup(null);
  }, [division]);
  useEffect(() => {
    // 大類、中類、小類更改時, 將最終要送出的行業別 id set into checkedItems.
    // section.id === 0 -> 全部大類 ; division.id === 0 -> 全部中類(一個大類下) ; group.id === 0 -> 全部小類(一個中類下)
    let selected;
    if ((division === null && group === null) || (division && division.id === 0)) {
      selected = section;
    } else if (group === null || (group && group.id === 0)) {
      selected = division;
    } else {
      selected = group;
    }
    setCheckedItems(selected ? [selected] : []);
  }, [section, division, group, checkedItems]);

  // 資料更新時間
  useEffect(() => {
    const fetchData = async () => {
      try {
        const updateTimeRes = await Api().get('trend/industry/updateTime');
        const statTimeRangeRes = await Api().get('trend/industry/statTimeRange');
        updateTimeRes.data && setUpdateTime(updateTimeRes.data);
        statTimeRangeRes.data && setStatTimeRange(statTimeRangeRes.data);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, []);

  // 取得行業別下 IPC 範圍
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (apiParams.industrialClassIds && apiParams.industrialClassIds.length !== 0) {
          const ipc = await Api().get(
            'trend/industry/ipcRange?industrialClassId=' + apiParams.industrialClassIds[0],
          );
          ipc.data && setIpcList(ipc.data);
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams]);

  useEffect(() => {
    if (!isLoading) {
      // 行業別選單載入完畢後
      if (urlId) {
        // URL帶指定行業, 更新下拉選單及行業名稱
        const items = toDropdownItem([urlId]);
        setCheckedItems(items);
        setTitle(getTitle(items));
      }
    }
  }, [isLoading, urlId]);

  const searchBar = (
    <SearchBar>
      <IpcStyle>
        <IndustrialClassArrowDropdown
          data={industrialClassList}
          dataTitle="大類"
          selectedItem={section}
          setSelectedItem={setSection}
          withLeftRadius
          withRightArrow
          withSelectAll
        />
        <IndustrialClassArrowDropdown
          data={section && section.children ? section.children : []}
          dataTitle="中類"
          selectedItem={division}
          setSelectedItem={setDivision}
          withRightArrow
          withSelectAll
        />
        <IndustrialClassArrowDropdown
          data={division && division.children ? division.children : []}
          dataTitle="小類"
          selectedItem={group}
          setSelectedItem={setGroup}
          withSelectAll
        />
      </IpcStyle>
      <SearchButton
        onClick={() => {
          setApiParams({
            ...apiParams,
            industrialClassIds:
              checkedItems && checkedItems[0] && checkedItems[0].id === 0
                ? []
                : checkedItems && checkedItems[0] && [checkedItems[0].id],
            kind: activeStatTab.value,
          });
          setTitle(
            getTitle(
              checkedItems && checkedItems[0] && checkedItems[0].id === 0 ? [] : checkedItems,
            ),
          );
        }}
      />
    </SearchBar>
  );

  return (
    <StatContent
      sector="專利專區"
      feature="專利趨勢統計"
      subTitle={'更新時間 ' + dateFormatter(updateTime)}
      searchBar={searchBar}
    >
      <>
        <Tabs>
          {statTabs.map((item, idx) => (
            <Tab
              key={idx}
              active={item.value === activeStatTab.value}
              onClick={() => {
                if (item.value !== activeStatTab.value) {
                  setActiveStatTab(item);
                  setApiParams({
                    ...apiParams,
                    kind: item.value,
                  });
                }
              }}
            >
              {item.name}
            </Tab>
          ))}
        </Tabs>
        <Content>
          <Title>{title}</Title>
          <div>
            統計範圍 : {activeStatTab.name}近 5 年
            {statTimeRange &&
              statTimeRange.length === 2 &&
              ' (' + getStatRangeText(statTimeRange) + ') '}
            資料、趨勢統計皆以
            {activeStatTab.value === 'A' ? '申請日' : '發證日'}為主。
            <br />
            {apiParams.industrialClassIds && apiParams.industrialClassIds.length !== 0 ? (
              <ExpandableBoxStyle>
                <ExpandableBox config={{ maxLineNumber: 1, currentLineHeight: 1.5 }}>
                  {apiParams.industrialClassIds && apiParams.industrialClassIds.length !== 0
                    ? 'IPC範圍 : ' + ipcList.join('、')
                    : ''}
                </ExpandableBox>
              </ExpandableBoxStyle>
            ) : (
              ''
            )}
          </div>
          {/*總覽*/}
          <StatSummary apiParams={apiParams} />
          {/*國家趨勢*/}
          <StatCountry apiParams={apiParams} statTimeRange={statTimeRange} />
          {/*ipc趨勢*/}
          <StatIpc apiParams={apiParams} statTimeRange={statTimeRange} title={title} />
          {/*專利權人趨勢*/}
          <StatApplicant apiParams={apiParams} statTimeRange={statTimeRange} />
        </Content>
      </>
    </StatContent>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isLoading: state.industrialClassReducer.isLoading,
    industrialClassList: state.industrialClassReducer.industrialClassList,
  };
};

export default connect(
  mapStateToProps,
  null,
)(IndustrialTrendsLayout);
