import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const Container = styled.div`
  padding: 10px 0;
`;
const Title = styled.div`
  padding: 10px 20px;
  border-radius: 4px;
  color: #077790;
  background-color: #edf6f7;
  :hover {
    cursor: pointer;
  }
`;
const ToggleStyle = styled.div`
  float: right;
`;
const Text = styled.div<{ active: boolean }>`
  padding: 12px 20px;
  display: ${props => (props.active ? 'block' : 'none')};
  color: #444444;
  background-color: #f5fdfe;
`;

interface IProps {
  title: string;
  text: string;
  active: boolean;
}

function Item(props: IProps) {
  const [active, setActive] = useState(props.active);
  const toggleHandler = () => {
    setActive(prev => !prev);
  };
  return (
    <Container>
      <Title onClick={toggleHandler}>
        {props.title}
        <ToggleStyle onClick={toggleHandler}>
          {active ? (
            <FaChevronUp color="#077790" onClick={toggleHandler} />
          ) : (
            <FaChevronDown color="#077790" onClick={toggleHandler} />
          )}
        </ToggleStyle>
      </Title>
      <Text active={active} dangerouslySetInnerHTML={{ __html: props.text }} />
    </Container>
  );
}

export default Item;
