import React, { useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaSearchMinus, FaSearchPlus } from 'react-icons/fa';
import ReactImageCrop from 'react-image-crop';
import format from 'date-fns/format';
import 'react-image-crop/dist/ReactCrop.css';

import { Backdrop } from 'src/style/ModalStyle';
import { zModal } from 'src/style/theme/Z-Index';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';

import { Button } from 'src/style/theme/Common';
import { blue3, blue6, iceblue1, iceblue7, ultrawhite, iceblue8 } from 'src/style/theme/Color';

import { connect } from 'react-redux';

const Container = styled.div`
  border-radius: 4px;
  background-color: ${ultrawhite};
`;
const Header = styled.div`
  width: 100%;
  padding: 16px 32px;
  color: ${iceblue1};
  display: flex;
  justify-content: space-between;
  div {
    min-width: 160px;
    display: flex;
    align-items: center;
  }
  div:nth-child(1) {
    font-size: 20px;
    font-weight: 600;
  }
  div:nth-child(2) {
    text-align: center;
  }
  div:nth-child(3) {
    justify-content: flex-end;
  }
`;
const ZoomBtn = styled.i<{ margin?: string; disable: boolean }>`
  margin: ${({ margin }) => margin};
  display: block;
  svg {
    margin: 0 8px 0 0;
    font-size: 20px;
    color: ${({ disable }) => (disable ? iceblue7 : blue3)};
    vertical-align: middle;
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  }
`;
const Body = styled.div`
  margin: 0 32px;
  width: 928px;
  min-height: 240px;
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${iceblue8};
`;
const Content = styled.div`
  max-width: calc(100% - 8px);
  max-height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px transparent;
  }

  @media (min-width: 1024px) {
    max-width: calc(100vw - 360px);
  }
`;
const Footer = styled.div`
  margin: 16px 32px 32px;
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

interface IImgSize {
  width: number;
  height: number;
}
interface IProps extends IReduxMappingProps {
  imgSrc: string | undefined;
  setCroppedImg: React.Dispatch<React.SetStateAction<string | undefined>>;
  crop: ReactImageCrop.Crop | undefined;
  setCrop: React.Dispatch<React.SetStateAction<ReactImageCrop.Crop | undefined>>;
  setCroppedImgFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const CropImageModal: React.FC<IProps> = ({
  imgSrc,
  setCroppedImg,
  closeCustomModal,
  crop: propsCrop,
  setCrop: setPropsCrop,
  setCroppedImgFile,
}) => {
  // react-image-crop needs 'HTMLImageElement', not 'Ref.Object<HTMLImageElement>'
  const [imageRef, setImageRef] = useState<HTMLImageElement>();
  const [imgSize, setImgSize] = useState<IImgSize>();
  const [adjImgSize, setAdjImgSize] = useState<IImgSize>();
  const [imgCss, setImgCss] = useState<React.CSSProperties>({ backgroundColor: ultrawhite });
  const [crop, setCrop] = useState<ReactImageCrop.Crop | undefined>(propsCrop);
  const [disableZoomOut, setDisableZoomOut] = useState(false);
  const [disableZoomIn, setDisableZoomIn] = useState(false);
  const maxWidth = 880;
  const minHeight = 160;

  const onImageLoaded = (img: HTMLImageElement) => {
    setImageRef(img);

    const initMaxWidth = Math.round(window.screen.width * 0.3);
    const initMaxHeight = Math.round(window.screen.height * 0.5);

    const size = { width: initMaxWidth, height: img.height * (initMaxWidth / img.width) };
    if (size.height > initMaxHeight) {
      size.height = initMaxHeight;
      size.width = img.width * (initMaxHeight / img.height);
    }

    if (size.width > maxWidth) {
      size.width = maxWidth;
      size.height = img.height * (maxWidth / img.width);
      setDisableZoomIn(true);
    }
    if (size.height < minHeight) {
      setDisableZoomOut(true);
    }

    setImgSize({ ...size });
    setImgCss({ ...imgCss, width: `${size.width}px`, height: `${size.height}px` });
  };

  const onCropChange = (cropConfig: ReactImageCrop.Crop) => {
    setCrop({ ...cropConfig });
  };

  /** for IE 11 image transform */
  const handleDataUrltoFile = (dataUrl: string, filename: string) => {
    const arr = dataUrl.split(',');
    const dataUrlArr = arr[0].match(/:(.*?);/);
    if (!dataUrlArr) {
      return new File([], filename, { type: 'image/png' });
    }
    const mime = dataUrlArr[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const makeClientCrop = useCallback(
    async (inputCropConfig: ReactImageCrop.Crop) => {
      const getCroppedImg = (image: any, cropConfig: any) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx &&
          ctx.drawImage(
            image,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height,
          );

        return new Promise<File>(resolve => {
          const imgFormat = 'png';
          const imgMime = `image/${imgFormat}`;
          const generateFilename = format(new Date(), 'yyyyMMddHHmmssSSS') + `.${imgFormat}`;
          if (!!window.navigator.msSaveBlob) {
            const msDataUrl = canvas.toDataURL(imgMime);
            const msFile = handleDataUrltoFile(msDataUrl, generateFilename);
            if (msFile.size === 0) {
              console.error('Canvas transform fail');
              return;
            }
            setCroppedImg(prev => {
              prev && window.URL.revokeObjectURL(prev);
              return msDataUrl;
            });
            resolve(msFile);
          } else {
            canvas.toBlob((blob: Blob | null) => {
              if (blob) {
                // react-image-crop 不會幫忙釋放記憶體, 須自行釋放
                const dataUrl = window.URL.createObjectURL(blob);
                setCroppedImg(prev => {
                  prev && window.URL.revokeObjectURL(prev);
                  return dataUrl;
                });
                resolve(new File([blob], generateFilename));
              } else {
                console.error('Canvas is empty');
                return;
              }
            }, imgMime);
          }
        });
      };
      if (imageRef && inputCropConfig.width && inputCropConfig.height) {
        const croppedImgFile = await getCroppedImg(imageRef, inputCropConfig);
        setCroppedImgFile(croppedImgFile);
      }
    },
    [imageRef, setCroppedImg, setCroppedImgFile],
  );

  const handleBrowserCrop = (cropConfig: ReactImageCrop.Crop) => {
    setPropsCrop({ ...cropConfig });
    cropConfig && makeClientCrop(cropConfig).then(() => closeCustomModal());
  };
  const handleCrop = () => {
    if (crop && crop.height !== 0 && crop.width !== 0) {
      handleBrowserCrop(crop);
    } else {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_EMPTY_CROP);
    }
  };
  const handleCropAll = () => {
    const cropAll = async (imgUrl: string) => {
      const imgFormat = 'png';
      const imgMime = `image/${imgFormat}`;
      const generateFilename = format(new Date(), 'yyyyMMddHHmmssSSS') + `.${imgFormat}`;

      imageRef &&
        setPropsCrop({
          x: 0,
          y: 0,
          width: imageRef.naturalWidth,
          height: imageRef.naturalHeight,
          unit: 'px',
        });

      if (!!window.navigator.msSaveBlob) {
        if (imageRef) {
          const canvas = document.createElement('canvas');
          canvas.width = imageRef.naturalWidth;
          canvas.height = imageRef.naturalHeight;
          const ctx = canvas.getContext('2d');

          ctx &&
            ctx.drawImage(
              imageRef,
              0,
              0,
              canvas.width,
              canvas.height,
              0,
              0,
              canvas.width,
              canvas.height,
            );

          const msDataUrl = canvas.toDataURL(imgMime);
          const msFile = handleDataUrltoFile(msDataUrl, generateFilename);
          if (msFile.size === 0) {
            console.error('Canvas transform fail');
            return;
          }
          setCroppedImg(prev => {
            prev && window.URL.revokeObjectURL(prev);
            return msDataUrl;
          });
          setCroppedImgFile(msFile);
        }
      } else {
        const fileBlob = await fetch(imgUrl).then(r => r.blob());
        const file = new File([fileBlob], generateFilename, { type: 'image/png' });
        const fileObjectUrl = URL.createObjectURL(file);

        setCroppedImg(prev => {
          prev && window.URL.revokeObjectURL(prev);
          return fileObjectUrl;
        });
        setCroppedImgFile(file);
      }
    };

    if (imgSrc) {
      cropAll(imgSrc).then(() => closeCustomModal());
    } else {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_READER_ERROR);
    }
  };
  const handleCancel = () => {
    closeCustomModal();
  };
  const handleZoomIn = () => {
    handleZoom(1.1);
  };
  const handleZoomOut = () => {
    handleZoom(0.9);
  };
  const handleZoom = (multiplier: number) => {
    setCrop(undefined);
    if (imgSize) {
      const currentSize = adjImgSize ? adjImgSize : imgSize;
      const size = {
        width: currentSize.width * multiplier,
        height: currentSize.height * multiplier,
      };

      if (currentSize.width * multiplier > maxWidth) {
        size.width = maxWidth;
        size.height = currentSize.height * (maxWidth / currentSize.width);
        setDisableZoomIn(true);
      } else {
        setDisableZoomIn(false);
      }

      setDisableZoomOut(false);
      if (currentSize.height * multiplier < minHeight) {
        if (imgSize.height > currentSize.height * multiplier) {
          setDisableZoomOut(true);
          if (imgSize.height < minHeight) {
            size.width = currentSize.width * (imgSize.height / currentSize.height);
            size.height = imgSize.height;
          } else {
            size.width = currentSize.width;
            size.height = currentSize.height;
          }
        }
      }

      const newSize = { ...size };
      setAdjImgSize(newSize);
      setImgCss({
        ...imgCss,
        width: `${newSize.width}px`,
        height: `${newSize.height}px`,
      });
    } else {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_READER_ERROR);
    }
  };

  const contentRef = useRef<HTMLDivElement>(null);

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <Header>
          <div>裁切圖片範圍</div>
          <div>{`選取範圍: ${crop && crop.width ? Math.floor(crop.width) : 0}px * ${
            crop && crop.height ? Math.floor(crop.height) : 0
          }px`}</div>
          <div>
            <ZoomBtn margin="0 8px 0 0" disable={disableZoomOut}>
              <FaSearchMinus onClick={handleZoomOut} />
            </ZoomBtn>
            <ZoomBtn disable={disableZoomIn}>
              <FaSearchPlus onClick={handleZoomIn} />
            </ZoomBtn>
          </div>
        </Header>
        <Body>
          <Content ref={contentRef as any}>
            {imgSrc && (
              <ReactImageCrop
                src={imgSrc}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onChange={onCropChange}
                imageStyle={imgCss}
                ruleOfThirds
              />
            )}
          </Content>
        </Body>
        <Footer>
          <Button type="button" template="primary" onClick={handleCrop}>
            裁切
          </Button>
          <Button type="button" template="primary-light" onClick={handleCropAll}>
            選擇全圖
          </Button>
          <Button type="button" onClick={handleCancel}>
            取消
          </Button>
        </Footer>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(CropImageModal);
