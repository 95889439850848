import React from 'react';
import styled from 'styled-components';

import SearchBar from 'src/components/common/search-bar/SearchBar';

import BG_IMG from 'src/assets/images/head-bg-2x.png';

const Container = styled.div`
  margin: -62px 0 0;
  position: relative;
`;
const SearchArea = styled.div`
  height: 162px;
  top: 62px;
  left: 0;
  right: 0;
  position: absolute;
`;
const Anchor = styled.div`
  margin: auto;
  width: 60%;
  min-width: 720px;
  text-align: center;
  > span {
    color: #003f54;
    display: block;
    padding: 36px 0 8px;
    font-size: 18px;
  }
`;
const Background = styled.div`
  height: 226px;
  background-image: url(${BG_IMG});
  background-size: 100% 100%;
`;

const banner: React.FC = () => {
  return (
    <Container>
      <Background />
      <SearchArea>
        <Anchor>
          <span>歡迎來到 iPKM，這裡是您最便利的專利好夥伴。</span>
          <SearchBar />
        </Anchor>
      </SearchArea>
    </Container>
  );
};

export default banner;
