// Primary
export const blue1 = '#003f54' as '#003f54';
export const blue1rgba = (opacity: number) => `rgba(0,63,84,${opacity})`;
export const blue2 = '#077790' as '#077790';
export const blue2rgba = (opacity: number) => `rgba(7,119,144,${opacity})`;
export const blue3 = '#0cafcc' as '#0cafcc';
export const blue3rgba = (opacity: number) => `rgba(12,175,204,${opacity})`;
export const blue4 = '#44c1d7' as '#44c1d7';
export const blue4rgba = (opacity: number) => `rgba(68,193,215,${opacity})`;
export const blue5 = '#7dd3e2' as '#7dd3e2';
export const blue5rgba = (opacity: number) => `rgba(125,211,226,${opacity})`;
export const blue6 = '#b5e4ec' as '#b5e4ec';
export const blue6rgba = (opacity: number) => `rgba(181,228,236,${opacity})`;
export const blue7 = '#edf6f7' as '#edf6f7';
export const blue7rgba = (opacity: number) => `rgba(237,246,247,${opacity})`;
export const blue8 = '#f5fdfe' as '#f5fdfe';
export const blue8rgba = (opacity: number) => `rgba(245,253,254,${opacity})`;

// Secondary
export const pink1 = '#ad0026' as '#ad0026';
export const pink1rgba = (opacity: number) => `rgba(173,0,38,${opacity})`;
export const pink2 = '#ca0b38' as '#ca0b38';
export const pink2rgba = (opacity: number) => `rgba(202,11,56,${opacity})`;
export const pink3 = '#e7154d' as '#e7154d';
export const pink3rgba = (opacity: number) => `rgba(231,21,77,${opacity})`;
export const pink4 = '#f33963' as '#f33963';
export const pink4rgba = (opacity: number) => `rgba(243,57,99,${opacity})`;
export const pink5 = '#ff5c79' as '#ff5c79';
export const pink5rgba = (opacity: number) => `rgba(255,92,121,${opacity})`;
export const pink6 = '#ff8b9f' as '#ff8b9f';
export const pink6rgba = (opacity: number) => `rgba(255,139,159,${opacity})`;
export const pink7 = '#ffbbc5' as '#ffbbc5';
export const pink7rgba = (opacity: number) => `rgba(255,187,197,${opacity})`;
export const pink8 = '#ffeaec' as '#ffeaec';
export const pink8rgba = (opacity: number) => `rgba(255,234,236,${opacity})`;

// BlueGray
export const iceblue1 = '#263237' as '#263237';
export const iceblue1rgba = (opacity: number) => `rgba(38,50,55,${opacity})`;
export const iceblue2 = '#424d52' as '#424d52';
export const iceblue2rgba = (opacity: number) => `rgba(66,77,82,${opacity})`;
export const iceblue3 = '#5f686c' as '#5f686c';
export const iceblue3rgba = (opacity: number) => `rgba(95,104,108,${opacity})`;
export const iceblue4 = '#7b8387' as '#7b8387';
export const iceblue4rgba = (opacity: number) => `rgba(123,131,135,${opacity})`;
export const iceblue5 = '#979ea1' as '#979ea1';
export const iceblue5rgba = (opacity: number) => `rgba(151,158,161,${opacity})`;
export const iceblue6 = '#b3b9bc' as '#b3b9bc';
export const iceblue6rgba = (opacity: number) => `rgba(179,185,188,${opacity})`;
export const iceblue7 = '#d0d4d6' as '#d0d4d6';
export const iceblue7rgba = (opacity: number) => `rgba(208,212,214,${opacity})`;
export const iceblue8 = '#eceff1' as '#eceff1';
export const iceblue8rgba = (opacity: number) => `rgba(236,239,241,${opacity})`;
export const white = '#f9f9f9' as '#f9f9f9';
export const whitergba = (opacity: number) => `rgba(249,249,249,${opacity})`;
export const white2 = '#ffffff' as '#ffffff';
export const white2rgba = (opacity: number) => `rgba(255,255,255,${opacity})`;

// Alarm
export const alarm1 = '#1f0b00';
export const alarm2 = '#521c00';
export const alarm3 = '#852d00';
export const alarm4 = '#b83f00';
export const alarm5 = '#eb5000';
export const alarm6 = '#ff6b1f';
export const alarm7 = '#ff8d52';
export const alarm8 = '#ffae85';
export const alarm9 = '#ffd0b8';

// TBD
export const black1 = '#333333';
export const red1 = '#ff0000';
export const green1 = '#12ad46';

export const ultrawhite = '#ffffff';
export const ultrawhitergba = (opacity: number) => `rgba(255,255,255,${opacity})`;
export const ultrablack = '#000000';
export const ultrablackrgba = (opacity: number) => `rgba(0,0,0,${opacity})`;

export const fontblack1 = '#263237';
export const fontblack2 = '#666666';

export const headerborder = '#d3e5e8';

/** 專利類別 (A:公開 | B:公告 | U:新型) */
export const patentKindColors: { [key: string]: string } = {
  A: blue3,
  B: pink5,
  U: '#ff9639',
};

/** 專利趨勢折線圖 */
export const trendsLineChartColors: { [key: string]: string } = {
  0: iceblue7,
  1: blue2,
};

// 知識地圖
interface ClusterMapColorType {
  bg: string;
  txt: string;
}
export const clusterMapColors: { [key: number]: ClusterMapColorType } = {
  0: { bg: '#ffbbc5', txt: '#ca0b38' },
  1: { bg: '#f9bbb6', txt: '#e64f43' },
  2: { bg: '#ffc28c', txt: '#eb5000' },
  3: { bg: '#fbe7b4', txt: '#c78f00' },
  4: { bg: '#acdfb2', txt: '#359d41' },
  5: { bg: '#bbdcea', txt: '#077790' },
  6: { bg: '#7dd3e2', txt: '#077790' },
  7: { bg: '#cac4d2', txt: '#7e5aad' },
  8: { bg: '#d0d4d6', txt: '#5f686c' },
  9: { bg: '#d0c4c4', txt: '#7c4141' },
};
export const clusterMapDefaultColor: ClusterMapColorType = {
  bg: '#0cafcc',
  txt: '#ffffff',
};
export const clusterMapLv2Color: ClusterMapColorType = {
  bg: '#979ea1',
  txt: '#ffffff',
};
export const clusterMapLv3Color: ClusterMapColorType = {
  bg: '#d0d4d6',
  txt: '#5f686c',
};

// 專利趨勢統計
export const trendsGroupBarChartColors: { [key: string]: string } = {
  TW: '#077790',
  US: '#ffcc4e',
  EP: '#ff5c79',
  JP: '#3271ed',
  CN: '#69ccec',
  KR: '#ff954e',
};
