export enum SystemMessage {
  // 專利檢索
  /** 請輸入關鍵字 */
  SEARCH_KEYWORD_EMPTY = '請輸入關鍵字',
  /** 請輸入完整查詢條件 */
  SEARCH_CONDITION_INCOMPLETE = '請輸入完整查詢條件',
  /** 請輸入查詢號碼 */
  SEARCH_NUMBER_EMPTY = '請輸入查詢號碼',
  /** 請輸入文字或上傳檔案 */
  SEARCH_BY_TEXT_INPUT_EMPTY = '請輸入文字或上傳檔案',
  /** 查無搜尋結果 */
  SEARCH_RESULT_EMPTY = '查無搜尋結果',

  // 專利追蹤
  /** 專利追蹤成功 */
  PATENT_SUBSCRIPTION_SUCCESS = '專利追蹤成功',
  /** 追蹤名稱為必填欄位 */
  PATENT_SUBSCRIPTION_NAME_EMPTY = '追蹤名稱為必填欄位',

  // 統計分析
  /** 請選擇分析項目 */
  STATISTICS_ANALYSIS_ITEM_UNSELECTED = '請選擇分析項目',
  /** 請選擇圖表類型 */
  STATISTICS_CHART_TYPE_UNSELECTED = '請選擇圖表類型',

  // 功效矩陣
  /** 欄位至少需一筆資料 */
  MATRIX_FIELD_EMPTY = '欄位至少需一筆資料',
  /** 數量已達上限 */
  MATRIX_FIELD_LIMIT = '數量已達上限',
  /** 數量超過上限 */
  MATRIX_FIELD_LIMIT_OVER = '數量超過上限',
  /** 檔案上傳失敗 */
  MATRIX_FILE_UPLOAD_FAILED = '檔案上傳失敗',
  /** 檔案解析失敗 */
  MATRIX_FILE_PARSE_FAILED = '檔案解析失敗',
  /** 請上傳CSV格式之設定檔。 */
  MATRIX_FILE_UPLOAD_FORMAT_CHECK = '請上傳CSV格式之設定檔。',
  /** 檔案大小超過限制, 請上傳 1Mb 以下的檔案 */
  MATRIX_FILE_UPLOAD_LIMIT_OVER = '檔案大小超過限制, 請上傳 1Mb 以下的檔案',
  /** 請確認您上傳的檔案內容。 */
  MATRIX_FILE_UPLOAD_CONTENT_CHECK = '請確認您上傳的檔案內容、符號與編碼(UTF-8)是否正確。',

  // 統計分析 & 功效矩陣
  /** 搜尋結果數目不足以進行分析，請重新查詢！ */
  RESULT_COUNT_NOT_ENOUGH = '搜尋結果數目不足以進行分析，請重新查詢！',
  /** 資料尚未載入完畢，請稍待片刻！ */
  RESULT_IS_LOADING = '資料尚未載入完畢，請稍待片刻！',

  // 專利收藏
  /** 請先選擇收藏分類。 */
  PATENT_COLLECTION_CATEGORY_UNSELECTED = '請先選擇收藏分類。',
  /** 標籤不得為空白 */
  PATENT_COLLECTION_TAG_EMPTY = '標籤不得為空白',

  // 專利筆記
  /** 筆記內容不可空白 */
  PATENT_NOTE_EMPTY = '筆記內容不可空白',

  // 專利高亮關鍵字標籤
  /** 標籤已存在 */
  PATENT_HIGHLIGHT_KEYWORD_EXIST = '標籤已存在',
  /** 標籤最多十筆！ */
  PATENT_HIGHLIGHT_KEYWORD_LIMIT = '標籤最多十筆',
  /** 標籤不可空白 */
  PATENT_HIGHLIGHT_KEYWORD_EMPTY = '標籤不可空白',

  // 子會員
  /** 子會員帳號啟用成功 */
  SUBMEMBER_ENABLE_SUCCESS = '子會員帳號啟用成功',
  /** 子會員帳號停用成功 */
  SUBMEMBER_DISABLE_SUCCESS = '子會員帳號停用成功',

  // 專欄文章
  /** 目前尚無專欄文章 */
  COLUMNIST_ARTICLE_NO_DATA = '目前尚無專欄文章',

  // 以圖找圖
  /** 讀取圖片時發生錯誤 */
  SEARCH_BY_IMAGE_READER_ERROR = '讀取圖片時發生錯誤',
  /** 請選擇裁切範圍 */
  SEARCH_BY_IMAGE_EMPTY_CROP = '請選擇裁切範圍',
  /** LOC 分類已選取 */
  LOC_SELECTED = '該 LOC 已選取，請選擇其他選項',

  // 學界專家
  /** 讀取知識圖譜相關專家發生錯誤 */
  EXPERT_GRAPH_RELATED_EXPERTS_ERROR = '取得相關專家資訊發生錯誤',
}
